.icon{
    color: rgb(36, 103, 228);
    margin-bottom: 15px;
}

.iconandroid{
    color: rgb(35, 209, 12);
    margin-bottom: 15px;
}

.iconios{
    color: rgb(192, 192, 192);
    margin-bottom: 15px;
}

.botoespadrao{
    color: rgb(36, 103, 228);
    margin-bottom: 15px;
}

.btn-android{
    background-color: rgb(35, 209, 12);
    color: white;
 }

 .btn-ios{
    background-color: rgb(192, 192, 192);
    color: black;
 }

 .img-200-200 {
    width: 200px;
    padding: 10px 5px 10px 5px;
 }